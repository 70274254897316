import { useEffect, useState } from 'react';
import { assignSet, getAllSets } from '../api/set';
import { Set } from '../models/set';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

export interface AddSetProps {
  show: boolean;
  userId: string;
  setShow: (show: boolean) => void;
}

export const AddSetDrawer = (props: AddSetProps) => {
  const [allSets, setAllSets] = useState<Array<Set>>();

  useEffect(() => {
    const loadSets = async () => {
      const sets = await getAllSets();
      setAllSets(sets);
    };
    loadSets();
  }, []);

  return (
    <>
      <Drawer anchor="right" open={props.show} onClose={() => props.setShow(false)}>
        <Box>
          <Typography variant="h5">Add Set</Typography>
          <List>
            {allSets?.map((s: Set) => (
              <ListItem key={'Sets'} disablePadding>
                <ListItemButton
                  onClick={async () => {
                    if (s.id) await assignSet({ setId: s.id, userId: props.userId });
                    props.setShow(false);
                  }}
                >
                  <ListItemText primary={s.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
