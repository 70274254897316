import { CopySet, CreateSet, DeleteSet, SetAsDefaultSet } from './../models/set';
import { fetchWithReauth } from './auth';
import { AssignSet, Set } from '../models/set';
import { GetSetsByUser, User } from '../models/user';
import { SetValidationResult } from '../models/set-validation-result';

export const setDefaultSetId = async (setId: string) => {
  let result = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/default/`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ setId: setId }),
  });
  return result.ok;
};

export const getDefaultSetId = async () => {
  let result = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/default/`, {
    method: 'GET',
    headers: { 'content-type': 'application/json' },
  });
  return result.text();
};

export const updateSet = async (set: Set) => {
  let newSet = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(set),
  });
  return newSet.json();
};

export async function createSet(set: CreateSet) {
  let newSet = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(set),
  });
  if (newSet.ok) return newSet.json();
  else throw Error(await newSet.text());
}

export async function copySet(copySet: CopySet) {
  let newSet = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/copy/`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(copySet),
  });
  if (newSet.ok) return newSet.json();
  else throw Error(await newSet.text());
}

export async function deleteSet(deleteSet: DeleteSet) {
  await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/`, {
    method: 'DELETE',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(deleteSet),
  });
}

export const getSetList = async (forceRelogin = false): Promise<Array<Set>> => {
  const setList = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/`, {}, forceRelogin);
  return setList.ok ? await setList.json() : undefined;
};

export const getLocalSetList = async (forceUpdate: boolean = false): Promise<Array<Set>> => {
  const localSetList = localStorage.getItem('setList');
  if (!localSetList || forceUpdate) {
    const setList = await getSetList(true);
    if (setList) {
      localStorage.setItem('setList', JSON.stringify(setList));
      return setList;
    }
    throw new Error('Could not get set List');
  }
  return JSON.parse(localSetList);
};

export const getSetsByUser = async (user: GetSetsByUser): Promise<Array<Set>> => {
  const setList = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/byUser`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(user),
  });
  return await setList.json();
};

export const getAllSets = async (): Promise<Array<Set>> => {
  const setList = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/all`, { method: 'GET' });
  return await setList.json();
};

export async function assignSet(assignSet: AssignSet) {
  await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/assign`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(assignSet),
  });
}

export async function unassignSet(assignSet: AssignSet) {
  await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/unassign`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(assignSet),
  });
}

export async function validateSet(setId: string): Promise<SetValidationResult> {
  const setValidationResult = await fetchWithReauth(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/set/validate/${setId}`, { method: 'GET' });
  return await setValidationResult.json();
}
