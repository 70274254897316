import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigation, useNavigate } from 'react-router-dom';
import { Login } from './screens/Login';
import { MySets } from './screens/MySets';
import { MyExercises } from './screens/MyExercises';
import { getLocalUser, isAdminUser, isLoggedIn } from './api/user';
import { Users } from './screens/Users';
import { User } from './screens/User';
import { Register } from './screens/Register';
import { VerificationComplete } from './screens/VerificationComplete';
import { ForgotPassword } from './screens/ForgotPassword';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BodyZonePartManager } from './screens/BodyZoneManager';
import { Export } from './screens/Export';
import { Privacy } from './screens/Privacy';
import { Themes } from './screens/Themes';
import { createContext, useContext, useEffect, useState } from 'react';
import { BodyZone, MuscleTag } from './models/body-zone';
import { useMuscleTagMap } from './hooks/use-muscle-tag-map.hook';
import { useBodyZoneList } from './hooks/use-bodyzone-list.hook';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { NavDrawer } from './components/NavDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';
import { MyAccount } from './screens/MyAccount';

export type AppContextType = {
  fileCache: Map<string, string>;
  bodyZoneList: BodyZone[];
  muscleTagList: MuscleTag[];
  muscleTagMap: Map<string, MuscleTag>;
  extraTitle: string | undefined;
  setExtraTitle: (title: string) => void;
  refreshBodyZoneList: () => void;
  refreshMuscleTagMap: () => void;
};

export const AppContext = createContext<AppContextType>({
  fileCache: new Map<string, string>(),
  muscleTagList: [],
  extraTitle: undefined,
  bodyZoneList: [],
  refreshMuscleTagMap: () => {},
  refreshBodyZoneList: () => {},
  setExtraTitle: () => {},
  muscleTagMap: new Map<string, MuscleTag>(),
});

const theme = createTheme({
  typography: {
    fontFamily: [
      'Quicksand',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: { mode: 'dark', primary: { main: '#606c38' } },
});

type Path = { path: string; title: string };
const paths: Array<Path> = [
  { path: '/my-sets', title: 'My Sets' },
  { path: '/set-viewer', title: 'My Exercises' },
  { path: '/my-account', title: 'My Account' },
  { path: '/body-zones', title: 'Body Zones' },
];

const getPathObject = (path: string) => {
  const pathObj = paths.find((po) => path.startsWith(po.path));
  return pathObj?.title ?? 'Plan and Set Manager';
};
//App Bar
function MyAppBar(props: { setDrawerOpen: (open: boolean) => void; drawerOpen: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton onClick={() => props.setDrawerOpen(!props.drawerOpen)} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {getPathObject(location.pathname)} {context.extraTitle}
        </Typography>
        {isLoggedIn() && (
          <div>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate('my-account');
                  handleClose();
                }}
              >
                My account
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

function App() {
  const [muscleTagList, muscleTagMap, reload] = useMuscleTagMap();
  const [bodyZoneList, refreshBodyZoneList] = useBodyZoneList();
  const [extraTitle, setExtraTitle] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter basename={'/'}>
        <NavDrawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
        <AppContext.Provider
          value={{
            extraTitle,
            setExtraTitle: (t) => setExtraTitle(t),
            fileCache: new Map<string, string>(),
            muscleTagMap,
            muscleTagList,
            refreshBodyZoneList,
            bodyZoneList,
            refreshMuscleTagMap: reload,
          }}
        >
          <MyAppBar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />

          <Routes>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/user/forgot-password" element={<ForgotPassword />} />
            <Route path="/user/verify/:result" element={<VerificationComplete />} />
            <Route
              path="/register"
              element={
                <GoogleReCaptchaProvider reCaptchaKey={'6LcP7k0kAAAAAKDs9U5qPzGOBxOJpm1F9sfnnET8'}>
                  <Register />
                </GoogleReCaptchaProvider>
              }
            />
            <Route path="/user/:userId" element={isAdminUser() ? <User /> : <Navigate to="/my-sets" />} />
            <Route path="/export" element={isAdminUser() ? <Export /> : <Navigate to="/my-sets" />} />
            <Route path="/users" element={isAdminUser() ? <Users /> : <Navigate to="/my-sets" />} />
            <Route path="/themes" element={isAdminUser() ? <Themes /> : <Navigate to="/body-zones" />} />
            <Route path="/login" element={<Login redirectLocation="/my-sets" />}></Route>
            <Route path="/my-account" element={!isLoggedIn() ? <Login redirectLocation="/my-account" /> : <MyAccount />}></Route>
            <Route path="/my-sets/:userId?" element={!isLoggedIn() ? <Login redirectLocation="/my-sets" /> : <MySets />}></Route>
            <Route path="/set-viewer/:setId" element={!isLoggedIn() ? <Login redirectLocation="/set-viewer" /> : <MyExercises />}></Route>
            <Route path="/" element={!isLoggedIn() ? <Login redirectLocation="/my-sets" /> : <Navigate to="/my-sets"></Navigate>}></Route>
            <Route path="/body-zones" element={isAdminUser() ? <BodyZonePartManager /> : <Navigate to="/body-zones" />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
