import 'react-image-crop/dist/ReactCrop.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalUser } from '../api/user';
import { ExerciseComponent } from '../components/ExerciseComponent';
import { Exercise } from '../models/exercise';
import { CreateExerciseModal } from '../components/modals/CreateExerciseModal';
import { AddExercisesDrawer } from '../components/AddExerciseDrawer';
import { TextScanModal } from '../components/modals/TextScanModal';
import { EditExerciseModal } from '../components/modals/EditExerciseModal';
import { FilterProps } from '../models/filter-props';
import { filter } from '../utils/exercise-filter';
import { AppContext } from '../App';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip, Box, Button, Container, Fab, Grid, InputAdornment, MenuItem, Paper, Select, Stack, SxProps, TextField, Typography, Zoom } from '@mui/material';
import { useSet } from '../hooks/set.hook';
import { useExercises } from '../hooks/exercises.hook';
import { useValidateSet } from '../hooks/validate-set.hook';
import EditIcon from '@mui/icons-material/Edit';
import { EditSetModal } from '../components/modals/EditSetModal';
import { Role } from '../models/user';

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
};

const fab = {
  color: 'primary' as 'primary',
  sx: fabStyle as SxProps,
  icon: <EditIcon />,
  label: 'Edit',
};

export const MyExercises = () => {
  let user = getLocalUser();
  const context = useContext(AppContext);
  const [showExerciseAdder, setShowExerciseAdder] = useState(false);
  const [showSetEditModal, setShowSetEditModal] = useState(false);
  const [showExerciseCreateModal, setShowExerciseCreateModal] = useState(false);
  const [showTextScanModal, setShowTextScanModal] = useState(false);
  const [textScanResult, setTextScanResult] = useState('');
  const [selectedExercise, setSelectedExercise] = useState<Exercise>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<Exercise>>();
  const isSetOwner = () => set?.creatorId === user?.id || user?.roles.includes(Role.Admin);
  const { setId } = useParams();
  const [set, refreshSet] = useSet(setId);
  const [validationResult, revaliate] = useValidateSet(setId);

  const [exerciseList, refreshExercises] = useExercises(setId);

  if (set) context.setExtraTitle(' - ' + set?.name);

  useEffect(() => {
    if (filterProps && exerciseList) setFilteredExercises(filter(exerciseList, filterProps));
    else setFilteredExercises(undefined);
  }, [filterProps, exerciseList]);

  return (
    <>
      <Fab
        onClick={() => {
          setShowSetEditModal(true);
        }}
        sx={fab.sx}
        aria-label={fab.label}
        color={fab.color}
      >
        {fab.icon}
      </Fab>
      <AddExercisesDrawer
        set={set}
        show={showExerciseAdder}
        setShow={setShowExerciseAdder}
        exerciseList={exerciseList}
        exerciseAdded={() => {
          refreshExercises();
          revaliate();
        }}
      />

      <Container>
        <Paper sx={{ my: 0, zIndex: 1, padding: 1, position: 'sticky', top: { xs: 60, sm: 65 } }}>
          <Stack sx={{ alignContent: 'center', alignItems: 'center' }} direction={'row'}>
            <Button onClick={() => setShowExerciseCreateModal(true)}>New</Button>
            <Button
              onClick={() => {
                setShowExerciseAdder(true);
              }}
            >
              Copy
            </Button>
            <TextField
              label="Filter"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterProps({ ...filterProps, name: e.target.value });
              }}
            />
            <Typography sx={{ textAlign: 'end', mx: 2 }}>Zone</Typography>
            <Select
              value={filterProps?.bodyZoneId !== '' && filterProps?.bodyZoneId ? filterProps?.bodyZoneId : 'any'}
              onChange={(e) => {
                if (e.target.value !== 'any') setFilterProps({ ...filterProps, bodyZoneId: e.target.value as any });
                else setFilterProps({ ...filterProps, bodyZoneId: '' });
              }}
            >
              <MenuItem key={'any'} value={'any'}>
                Any
              </MenuItem>
              {context.bodyZoneList?.map((bz) => (
                <MenuItem key={bz.id} value={bz.id}>
                  {bz.name}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ px: 2 }}>
              Set is{' '}
              {validationResult?.isValid ? (
                <Box>Valid</Box>
              ) : (
                <Tooltip title={validationResult?.validationErrors}>
                  <Box>Invalid</Box>
                </Tooltip>
              )}
            </Box>
          </Stack>
        </Paper>
        {set ? <EditSetModal set={set} refreshSet={() => refreshSet(true)} setShowCreateSetModal={setShowSetEditModal} showEditSetModal={showSetEditModal} /> : undefined}
        <TextScanModal
          show={showTextScanModal}
          onHide={(text: string) => {
            setTextScanResult(text);
            setShowTextScanModal(false);
          }}
          setShow={() => {
            setShowTextScanModal(false);
          }}
        />
        <CreateExerciseModal
          setId={setId}
          showTextScanModal={() => {
            setShowTextScanModal(true);
          }}
          textScanModalResult={textScanResult}
          exerciseCreated={(e) => {
            if (exerciseList) refreshExercises();
            revaliate();
          }}
          show={showExerciseCreateModal}
          setShow={setShowExerciseCreateModal}
        />
        {selectedExercise ? (
          <EditExerciseModal
            setExercise={setSelectedExercise}
            exercise={selectedExercise}
            set={set}
            showTextScanModal={() => {
              setShowTextScanModal(true);
            }}
            textScanModalResult={textScanResult}
            exerciseUpdated={(ex) => {
              if (exerciseList) refreshExercises();
              revaliate();
            }}
          />
        ) : (
          <></>
        )}

        <Grid gap={1} sx={{ my: 1 }} container>
          {filteredExercises
            ? filteredExercises.map((e, i) => (
                <ExerciseComponent
                  timeout={1000}
                  key={e.id}
                  onExerciseDeleted={() => {
                    exerciseList?.filter((ex) => ex.id !== e?.id);
                    revaliate();
                  }}
                  onClick={() => (isSetOwner() ? setSelectedExercise(e) : undefined)}
                  exercise={e}
                />
              ))
            : exerciseList?.map((e, i) => (
                <ExerciseComponent
                  timeout={1000}
                  key={e.id}
                  onExerciseDeleted={() => {
                    refreshExercises();
                    revaliate();
                  }}
                  onClick={() => (isSetOwner() ? setSelectedExercise(e) : undefined)}
                  exercise={e}
                />
              ))}
        </Grid>
      </Container>
    </>
  );
};
