import { useState } from 'react';
import { updateSet } from '../../api/set';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { Set } from '../../models/set';

export interface EditSetModalProps {
  showEditSetModal: boolean;
  set: Set;
  setShowCreateSetModal: (s: boolean) => void;
  refreshSet: () => void;
}

export const EditSetModal = (props: EditSetModalProps) => {
  const { set, showEditSetModal, refreshSet, setShowCreateSetModal } = props;

  const [setDescription, setSetDescription] = useState<string | undefined>(set.description);
  const [setName, setSetName] = useState<string>(set.name);

  return (
    <Modal open={showEditSetModal} onClose={() => setShowCreateSetModal(false)}>
      <Box sx={modalStyle}>
        <Typography variant="h4">Edit Set</Typography>
        <TextField value={setName} style={{ marginTop: 30 }} onChange={(e) => setSetName(e.target.value)} label="Name" />
        <Box>
          <TextField
            value={setDescription}
            label={'Set Description'}
            onChange={(v) => setSetDescription(v.target.value)}
            style={{ width: 250, marginTop: 10 }}
            multiline
            rows={4}
          />
        </Box>
        <Button
          style={{ marginTop: 10 }}
          onClick={async () => {
            setShowCreateSetModal(false);
            try {
              await updateSet({ ...set, name: setName || '', description: setDescription });
              refreshSet();
            } catch (e) {
              if (e instanceof Error) {
                alert(JSON.parse(e.message).message);
              }
            }
          }}
        >
          Save{' '}
        </Button>
        <Button style={{ marginTop: 10 }} onClick={() => setShowCreateSetModal(false)}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
