export interface LoginUser {
  username: string;
  password: string;
}

export interface RegisterUser {
  email: string;
  password: string;
  captchaToken: string;
}

export interface LocalUser extends LoginUser {
  access_token?: string;
  id: string;
  identityProviderToken?: string;
  roles: Array<Role>;
}

export interface CreateUser {
  username: string;
  password: string;
  active: boolean;
  roles: Array<Role>;
}

export interface GetSetsByUser {
  userId: string;
  setIds: Array<string>;
}

export interface User extends CreateUser {
  userId: string;
  setIds: Array<string>;
}

export enum Role {
  User = 'user',
  Uploader = 'uploader',
  Admin = 'admin',
}
