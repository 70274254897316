import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSetsByUser, unassignSet } from '../api/set';
import { getUserById } from '../api/user';
import { User as UserModel } from '../models/user';
import { Set } from '../models/set';
import { AddSetDrawer } from '../components/AddSetDrawer';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';

export const User = () => {
  const { userId } = useParams();
  const [user, setUser] = useState<UserModel>();
  const [sets, setSets] = useState<Array<Set>>([]);
  const [showSets, setShowSets] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      if (userId) {
        const user = await getUserById(userId);
        setUser(user);
        const sets = await getSetsByUser(user);
        setSets(sets);
        console.log(sets);
      }
    };
    loadUser();
  }, [showSets, refresh]);

  return (
    <>
      {user ? <AddSetDrawer setShow={setShowSets} show={showSets} userId={user?.userId} /> : null}
      <Box sx={{ padding: 2 }}>
        <Button
          onClick={() => {
            setShowSets(true);
          }}
        >
          Add Set
        </Button>

        <Grid sx={{ my: 0.1 }} spacing={2} container>
          <Grid item>
            {' '}
            <TextField
              value={user?.username}
              onChange={(e) => {
                if (user) {
                  setUser({ ...user, username: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={user?.active}
                  onChange={(e) => {
                    if (user) {
                      setUser({ ...user, active: !user.active });
                    }
                  }}
                />
              }
              label="Active"
            />
          </Grid>
        </Grid>
        <Typography sx={{ mt: 2 }} variant="h5">
          User's Sets
        </Typography>
        <Grid sx={{ margin: 0.2 }} spacing={2} container>
          {sets?.map((s) => (
            <>
              <Grid xs={3} item>
                <Typography variant="h6">Name: {s.name}</Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="h6">Description: {s.description}</Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography variant="h6">Creator: {s.creatorId}</Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography variant="h6">{s.modificationTimestamp}</Typography>
              </Grid>
              <Grid xs={2} item className="xs">
                <Button
                  onClick={async () => {
                    if (s.id && user) await unassignSet({ setId: s.id, userId: user?.userId });
                    setRefresh(!refresh);
                  }}
                >
                  Remove
                </Button>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
};
