import { jwtDecode } from 'jwt-decode';
import { LoginUser, LocalUser, User } from '../models/user';
import { deleteLocalUser, getLocalUser, setLocalUser } from './user';

export async function login(user: LoginUser): Promise<boolean> {
  const resp = await fetch(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/user/auth/login`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    method: 'POST',
  });
  if (resp.ok) {
    console.log('User successfully logged in');
    const respJson = await resp.json();
    setLocalUser({ ...user, id: respJson.id, roles: respJson.roles });
    const token = respJson.access_token;
    localStorage.setItem('token', JSON.stringify(token));
    return true;
  } else {
    console.warn('Login failed');
    return false;
  }
}

export async function loginWithGoogleCode(code: string): Promise<boolean> {
  console.log(code);
  const resp = await fetch(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/auth/redeem-google-code?code=${encodeURI(code)}`, {
    method: 'GET',
  });
  if (resp.ok) {
    console.log('User successfully logged in');
    const respJson = (await resp.json()) as LocalUser;
    console.log(respJson);
    setLocalUser({ identityProviderToken: respJson.identityProviderToken, password: '', username: respJson.username, id: respJson.id, roles: respJson.roles });
    const token = respJson.access_token;
    localStorage.setItem('token', JSON.stringify(token));

    return true;
  } else {
    console.warn('Login failed');
    return false;
  }
}

export const logout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isLoggedIn');
  deleteLocalUser();
};

//TODO: This is problematic if used in parallel, Promise.all etc.
//Better to update session jwt in this case. (separate method)
export async function fetchWithReauth(input: RequestInfo, init?: RequestInit | undefined, force?: boolean) {
  const localUser = getLocalUser();
  //TODO: Differentiate login types
  let result = await fetch(input, {
    ...init,
    headers: { ...init?.headers, ...{ Authorization: `Bearer ${getToken()}` } },
  });
  if ((result.status === 401 || force) && localUser) {
    console.log('Session is probably expired. Trying to login with stored credentials.');
    if (localUser.username && localUser.password) {
      const result = await login(localUser);
      if (!result) {
        await logout();
        window.location.href = '/';
      }
    } else {
      try {
        const resp = await fetch(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}/auth/refresh-google-token?token=${localUser.identityProviderToken}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
        });
        if (resp.ok) {
          const res = await resp.json();
          if (res.identityProviderToken) setLocalUser({ identityProviderToken: res.identityProviderToken, password: '', username: res.username, id: res.id, roles: res.roles });
          localStorage.setItem('token', JSON.stringify(res.access_token));
        } else {
          await logout();
          window.location.href = '/';
        }
      } catch (ex) {
        await logout();
      }
    }
    return await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        ...{ Authorization: `Bearer ${getToken()}` },
      },
    });
  } else return result;
}

const getToken = () => {
  if (localStorage.getItem('token')) return JSON.parse(localStorage.getItem('token') || '');
  else return '';
};
