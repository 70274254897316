import { useEffect, useState } from 'react';
import { deleteUser, listUsers } from '../api/user';
import { User } from '../models/user';
import { CreateUserModal } from '../components/modals/CreateUserModal';
import { useNavigate } from 'react-router-dom';
import { getDefaultSetId as getDefaultSetIdApi } from '../api/set';
import { Button, List, ListItem, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type DeleteUserDialogProps = { open: boolean; userId?: string; setOpen: (open: boolean) => void };

const DeleteUserDialog = (props: DeleteUserDialogProps) => {
  const { open, setOpen } = props;

  const handleClose = async (del: boolean) => {
    if (del) await deleteUser(props.userId ?? '');
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Delete this user?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Really delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} autoFocus>
            Delete
          </Button>
          <Button onClick={() => handleClose(false)}>Abort</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Users = () => {
  const [userList, setUserList] = useState<Array<User>>();
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false);
  const [defaultSetId, setDefaultSetId] = useState<string>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userList || showCreateUserModal === false) {
      const getUsers = async () => {
        const users = await listUsers();
        setUserList(users);
      };
      getUsers();
    }
    if (!defaultSetId) {
      const getDefaultSetId = async () => {
        const defaultSetId = await getDefaultSetIdApi();
        setDefaultSetId(defaultSetId);
      };
      getDefaultSetId();
    }
  }, [showCreateUserModal, deleteDialogOpen]);

  return (
    <>
      <DeleteUserDialog userId={userIdToDelete} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />
      <Button
        onClick={() => {
          setShowCreateUserModal(true);
        }}
      >
        Create User
      </Button>

      <Typography variant="h6"> Default Set Id: {defaultSetId ?? 'Not Set'}</Typography>
      <List>
        {userList?.map((u) => {
          return (
            <ListItem>
              User:&nbsp;<b>{u.username}</b>&nbsp; Active: {u.active === true ? 'Yes' : 'No'}&nbsp; User Roles: &nbsp;
              {u.roles.map((r) => r + ' ')}&nbsp;
              <Button onClick={() => navigate(`/my-sets/${u.userId}`)} style={{}}>
                Edit Sets
              </Button>
              <Button onClick={() => navigate(`/user/${u.userId}`)} style={{}}>
                Show Sets
              </Button>
              <Button
                onClick={async () => {
                  setUserIdToDelete(u.userId);
                  setDeleteDialogOpen(true);
                }}
                style={{}}
              >
                Delete User
              </Button>
            </ListItem>
          );
        })}
      </List>

      <CreateUserModal showCreateUserModal={showCreateUserModal} setShowCreateUserModal={setShowCreateUserModal} />
    </>
  );
};
