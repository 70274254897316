import { Exercise } from '../models/exercise';
import { ImageFromServer } from './ImageFromServer';
import { TagIntensityList } from './TagIntensityList';
import { useContext, useState } from 'react';
import { AppContext } from '../App';
import { Box, Button, Fade, Grid, Grow, Link, Paper, Stack, Typography } from '@mui/material';
import { DeleteModal } from './modals/DeleteSetModal';
import { deleteExercise } from '../api/exercise';

interface ExerciseProps {
  exercise: Exercise;
  timeout: number;
  onClick?: () => void;
  onExerciseDeleted?: () => void;
}

//TODO: Refactor this to extra Component, clean up related states,
// const mapExerciseToComponent = (e: Exercise) => (
//   <ListGroup.Item style={{ backgroundColor: 'white' }}>
//     <DeleteModal
//       show={showDeleteModal}
//       setShow={setShowDeleteModal}
//       nameOfObjectToDelete="Exercise"
//       handleDelete={async () => {
//         setExerciseList(exerciseList?.filter((ex) => ex.id !== exerciseToDelete?.id));
//         await deleteExercise(exerciseToDelete?.id ?? '');
//         setShowDeleteModal(false);
//       }}
//     ></DeleteModal>
//     <span
//       onClick={() => {
//         if (isSetOwner()) {
//           setSelectedExercise(e);
//         }
//       }}
//     >
//       <ExerciseComponent key={e.id} exercise={e} />
//     </span>
//     <div style={{ display: 'flex', justifyContent: 'end', margin: 20 }}>
//       {isSetOwner() ? (
//         <Button
//           size="sm"
//           onClick={async (ev) => {
//             setExerciseToDelete(e);
//             setShowDeleteModal(true);

//             ev.preventDefault();
//             ev.stopPropagation();
//           }}
//         >
//           Delete
//         </Button>
//       ) : null}
//     </div>
//   </ListGroup.Item>
// );

export const ExerciseComponent = (props: ExerciseProps) => {
  const context = useContext(AppContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //const isSetOwner = () => set?.creatorId === user?.id;

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Exercise"
        handleDelete={async () => {
          await deleteExercise(props.exercise?.id ?? '');
          setShowDeleteModal(false);
          if (props.onExerciseDeleted) props.onExerciseDeleted();
        }}
      ></DeleteModal>
      <Grow timeout={props.timeout} in={true}>
        <Paper onClick={props.onClick} style={{ width: '100%' }} elevation={4}>
          <Grid item>
            <Grid xs={12} container>
              <Grid item sx={{ margin: 2 }} xs={12}>
                <Typography variant="h3">{props.exercise.name}</Typography>
              </Grid>
              <Grid item container style={{ margin: 10, justifyContent: 'center' }} md={3} xs={12}>
                <Box>
                  <ImageFromServer sx={{ borderRadius: 3, maxHeight: 300 }} thumbnail={true} alt="" id={props.exercise.imageId} />
                </Box>
              </Grid>

              <Grid item style={{ margin: 10 }} md={8}>
                <Stack>
                  <Grid container>
                    <Grid xs={12} item>
                      <Typography variant="h6">Zone: {context.bodyZoneList.find((bz) => bz.id === props.exercise.mainBodyZoneId)?.name ?? 'Not selected'}</Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ marginBottom: 0.5 }} variant="h6">
                      Muscles:
                    </Typography>
                    <TagIntensityList muscleIntensities={props.exercise.muscleIntensities} />
                  </Box>
                  <Box sx={{ my: 1, minHeight: 50 }}>
                    <Typography variant="h6">Description:</Typography>
                    <Typography>{props.exercise.description !== '' ? props.exercise.description : '-'}</Typography>
                  </Box>
                  <Grid sx={{ my: 1 }} container>
                    <Grid xs={2} item>
                      <Typography variant="h6">Weight: {props.exercise.templateConfig.weight || 'Not Set'}</Typography>
                    </Grid>
                    <Grid xs={2} item>
                      <Typography variant="h6">Repetitions: {props.exercise.templateConfig.repetitions || 'Not Set'}</Typography>
                    </Grid>
                    <Grid xs={8}></Grid>
                    {props.exercise.templateConfig.useTimer && (
                      <>
                        <Grid sx={{ mt: 1 }} xs={4} item>
                          <Typography variant="h6">Time: {props.exercise.templateConfig.time || 'Not Set'}</Typography>
                        </Grid>
                        <Grid sx={{ mt: 1 }} xs={4} item>
                          <Typography variant="h6">Init Time: {props.exercise.templateConfig.initTime || 'Not Set'}</Typography>
                        </Grid>
                        <Grid sx={{ mt: 1 }} xs={4} item>
                          <Typography variant="h6">Break Time: {props.exercise.templateConfig.breakTime || 'Not Set'}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid sx={{ mt: 1 }} container>
                    <Grid xs={3} item>
                      <Typography variant="h6">Alternate sides: {props.exercise.multiLimb ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  </Grid>
                  <Stack>
                    {props.exercise.exerciseLinks.map((el) => (
                      <Box>
                        <Link onClick={(e) => e.stopPropagation()} href={el.uri}>
                          {el.title}
                        </Link>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Stack sx={{ my: 3, mx: 3, width: '100%', alignItems: 'flex-end' }}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grow>
    </>
  );
};
